import React, { useEffect, useState } from 'react';

import img1 from "../../assets/images/sp10.png";
import img2 from "../../assets/images/sp14.png";
import img3 from "../../assets/images/sp3.png";
import img4 from "../../assets/images/sp13.png";
import img5 from "../../assets/images/sp5.png";
import im4 from "../../assets/images/sp7.png";
import im5 from "../../assets/images/sp8.png";
import im6 from "../../assets/images/sp11.png";
import im7 from "../../assets/images/sp12.png";
import story from "../../../src/assets/images/story2.png";
import v1 from "../../assets/images/v1.png";
import v2 from "../../assets/images/v2.png";
import v3 from "../../assets/images/v3.png";
import v4 from "../../assets/images/v4.png";
import v5 from "../../assets/images/v5.png";
import send from "../../assets/images/send.png";
import { getBlogsAsync } from '../../services/reducers/reciepeReducer';
import { useAppDispatch } from '../../hooks';
import { truncateText } from '../../utils/common';
import placeholder from '../../../src/assets/images/placeholderImage.png';
import moment from 'moment';
import { Link } from 'react-router-dom';

const OurStoryTwo: React.FC = () => {
  const dispatch = useAppDispatch();
  const [getBlogs, setGetBlogs] = useState<any>([]);
  const storedCountry = localStorage.getItem("selectedCountry");
  useEffect(() => {
    const request = {
      page: 1,
      pageSize: 5,
      country: "",
    };
    dispatch(getBlogsAsync(request)).then((res) => {
      if (res.payload) {
        const filteredBlogs = res.payload.data.filter((blog: any) =>
          blog?.tag?.includes("Anyfeast_Investor")
        );
        setGetBlogs(filteredBlogs);
      }
    });
  }, []);
  const convertDateWithMoment = (isoDate: string): string => {
    return moment(isoDate).format("MMMM D, YYYY");
  };
  const storedCode = localStorage.getItem('countryCode');
  return (
    <div className="bg-graybg flex flex-col items-center w-full">
      <div className="w-full aspect-w-16 aspect-h-9">
        {storedCode === "IN" && (
          <video width="640" height="360" controls loop>
            <source src="https://anyfeast.blob.core.windows.net/app-visuals-data/about_us_IN.mp4" type="video/mp4" />
          </video>
        )}
        {storedCode === "UK" && (
          <video width="640" height="360" controls loop>
            <source src="https://anyfeast.blob.core.windows.net/app-visuals-data/about_us_UK.mp4" type="video/mp4" />
          </video>
        )}
        {/* <iframe width="932" height="524" src="https://anyfeast.blob.core.windows.net/app-visuals-data/about_us_IN.mp4" title="Revolutionise Your Cooking with AnyFeast 🌟 | Explore Exciting Features Today!" frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen></iframe> */}
      </div>

      {/* Welcome Section */}
      <div className="relative pb-4 lg:pb-6 w-full">
        <div className="bg-red text-white pt-2 lg:pt-20 pb-20 lg:pb-32 text-center px-6 sm:px-10 md:px-20 lg:px-32 xl:px-40 w-full">
          <p className=" font-sans text-justify lg:text-xl text-[14px] leading-relaxed mx-auto mt-4 lg:mt-6">
            <b>Hi, I’m Pankaj Kale</b>—a foodie, traveller, and advocate for sustainability and innovation. AnyFeast was inspired by my journey of overcoming unhealthy eating habits and rediscovering the joy of cooking.
            <p>With a passion for fresh, authentic flavours, I set out to make healthy, sustainable cooking accessible to everyone.</p>
            <p>  At AnyFeast, we believe small actions create big change.By choosing local ingredients and reducing waste, we can support communities and build a healthier, more sustainable future.</p><p> Join us—let’s cook sustainably, shop local, and stay healthier, one meal at a time!</p>

          </p>
        </div>

        {/* Family Image */}
        <div className="flex justify-center lg:-mt-28 md:px-4 px-8 -mt-20 lg:px-none">
          <img
            src={story} // Replace with your image path
            alt="Family"
            className="rounded-[28px] shadow-lg"
          />
        </div>
      </div>

      {/* Sponsor Section */}
      <div className="lg:mt-12 mt-6 text-center w-full pb-10 lg:pb-10">
        <h3 className="lg:text-2xl text-xl font-bold mb-10 lg:mb-16 font-sans text-black">Proudly Supported By</h3>
        <div className="grid grid-cols-5 md:grid-cols-5 lg:grid-cols-5 gap-6 lg:px-10 px-4">
          <div className="flex justify-center items-center">
            <img src={img1} alt="Microsoft" className="lg:h-20 h-8" />
          </div>
          <div className="flex justify-center items-center">
            <img src={img2} alt="Founder Institute" className="lg:h-24 h-8" />
          </div>
          <div className="flex justify-center items-center">
            <img src={img3} alt="Virgin Start" className="lg:h-20 h-8" />
          </div>
          <div className="flex justify-center items-center">
            <img src={img4} alt="NatWest" className="lg:h-16 md:h-8 h-4" />
          </div>
          <div className="flex justify-center items-center">
            <img src={img5} alt="Wadhwani Foundation" className="lg:h-24 h-8" />
          </div>
        </div>
      </div>


      <div className="lg:max-w-7xl md:max-w-7xl lg:mx-auto md:mx-auto space-y-8 lg:pb-6 pb-6 lg:mx-none md:mx-6 mx-6">
        {/* Vision Section */}
        <div className="bg-white rounded-lg border shadow-xl overflow-hidden flex flex-row p-3 lg:p-6 lg:p-10 lg:gap-6 md:p-6 md:p-10 md:gap-6 space-x-0 mb-4 lg:mb-16 md:mb-16">
          {/* Text Section */}
          <div className="flex-1 text-left">
            <h1 className=" lg:text-4xl md:2xl font-bold text-black pb-2 lg:py-6 lg:pb-4 lg:border-b-4 md:py-6 md:pb-4 md:border-b-4 border-b-2 border-gray-500 font-sans">
              Our <span className="text-red">Vision</span>
            </h1>

            <p className="mt-2 lg:mt-8 md:mt-8 font-sans text-[#313131] leading-4 md:leading-relaxed lg:leading-relaxed text-[12px] lg:text-xl md:text-[15px]">
              <b>At AnyFeast Recipe Kits,</b> we envision a world where cooking transforms lives—promoting healthier eating, strengthening communities, and protecting our planet, one meal at a time. By harnessing the power of AI and innovative technology, we deliver <b>personalized, eco-friendly meal solutions </b> that make home cooking effortless, sustainable, and deeply rewarding. Our mission is to nourish both people and the planet, inspiring a future where food is smarter, greener, and more connected than ever.
            </p>
          </div>
          {/* Image Section */}
          <div className="flex-1 justify-end justify-items-end md:justify-items-center md:pt-8">
            <img
              src={im4}
              alt="Vision"
              className="hidden lg:block md:inline-block rounded-lg w-3/4 object-cover"
            />
            {/* Responsive Image */}
            <img
              src={im6}
              alt="Vision"
              className="lg:hidden md:hidden rounded-lg object-cover justify-items-center pt-6"
            />
          </div>
        </div>

        {/* Mission Section */}
        <div className="bg-white rounded-lg border shadow-xl overflow-hidden flex flex-row p-3 lg:p-6 lg:p-10 lg:gap-6 md:p-6 md:p-10 md:gap-6 space-x-0 mb-4 lg:mb-16 md:mb-16">
          {/* Image Section */}
          <div className="flex-1 justify-items-start md:justify-items-center md:pt-8">
            <img
              src={im5}
              alt="Vision"
              className="hidden lg:block md:inline-block rounded-lg w-3/4 object-cover"
            />
            {/* Responsive Image */}
            <img
              src={im7}
              alt="Vision"
              className="lg:hidden md:hidden rounded-lg object-cover justify-items-center pt-6"
            />
          </div>
          {/* Text Section */}
          <div className="flex-1 text-left">
            <h1 className=" lg:text-4xl md:2xl  font-bold text-[#313131] pb-2 lg:py-6 lg:pb-4 lg:border-b-4 md:py-6 md:pb-4 md:border-b-4 border-b-2 border-gray-500 font-sans">
              Our <span className="text-red">Mission</span>
            </h1>
            <p className="mt-2 lg:mt-8 md:mt-8 font-sans text-[#313131] leading-4 md:leading-relaxed lg:leading-relaxed text-[12px] lg:text-xl md:text-[15px]">
              At AnyFeast, we empower busy professionals to enjoy healthier, more sustainable meals with our <b>AnyFeast Recipe Kit</b>. By combining responsibly sourced ingredients, easy-to-follow culinary education, and cutting-edge AI technology, we personalize meal solutions, minimize food waste, and support local farmers and producers. Our mission is to revolutionize home cooking—making it effortless, delicious, and eco-friendly—while fostering a healthier planet and stronger communities. Discover a smarter way to cook, eat, and live with AnyFeast Recipe Kit.<b> #HealthyEating #SustainableFood #AIRecipes</b>
            </p>
          </div>
        </div>
      </div>

      <div className="relative pb-4 lg:pb-6 w-full">
        <div className="mb-4 lg:mb-none md:mb-none bg-[#FFE1DF] lg:h-auto h-auto text-[#1F1F1F] font-sans lg:pt-20 lg:pb-32 md:pt-16 md:pb-20 text-center py-3 lg:py-none md:py-none px-6 md:px-20 lg:px-32 xl:px-40 w-full">
          <h1 className='lg:text-4xl md:2xl font-bold'>Our Company Values</h1>
          <p className="font-normal lg:text-xl md:text-lg text-sm leading-relaxed mx-auto font-sans mt-2 mb-2 lg:mx-24 lg:mb-none lg:mt-6">
            Just like a delicious dish, any company worth its salt is made of a variety of different people, teams and
            departments. No matter how many people help us grow AnyFeast, we all share the same values, which are
          </p>
        </div>


        <div className="lg:grid md:grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 lg:gap-8 md:gap-6 px-2 lg:-mt-20 lg:!pl-20 md:-mt-20 md:!pl-4 lg:mb-8 md:mb-6 mb-2 justify-items-center">
          <div className="max-w-sm p-3">
            <img
              src={v1}
              alt="Passion"
              className="rounded-lg mb-4"
            />
            <h1 className="lg:text-lg text-xl font-bold mb-2">Passion</h1>
            <p className="font-normal md:text-sm leading-relaxed text-gray-600">
              Cooking is more than just making meals—it’s an art, a skill, and a way to see the world differently. At <b>AnyFeast Recipe Kits</b>, we are passionate about bringing people together through food, sharing exciting new ways to create delicious, home-cooked meals with ease.
            </p>
          </div>
          <div className="max-w-sm p-3">
            <img
              src={v2}
              alt="Innovation"
              className=" rounded-lg mb-4"
            />
            <h1 className="lg:text-lg text-xl font-bold mb-2">Innovation</h1>
            <p className="font-normal md:text-sm leading-relaxed text-gray-600">
              Traditional recipes have stood the test of time, but we’re not afraid to innovate! With AI-driven personalization, smart meal planning, and cutting-edge technology, <b>AnyFeast Recipe Kits</b> make it easier than ever to explore global cuisines in a convenient, time-saving way—without sacrificing authenticity.
            </p>
          </div>
          <div className="max-w-sm  p-3">
            <img
              src={v3}
              alt="Integrity"
              className="rounded-lg mb-4"
            />
            <h1 className="lg:text-lg text-xl font-bold mb-2">Integrity</h1>
            <p className="font-normal md:text-sm leading-relaxed text-gray-600">
              We believe in doing good for people and the planet. That’s why we source responsibly, package our ingredients in eco-friendly materials, and donate a portion of our profits to support communities in need. With <b>AnyFeast Recipe Kits</b>, you can cook with confidence, knowing your meals are making a difference.
            </p>
          </div>

          <div className="lg:col-span-3 md:grid lg:flex md:flex justify-center gap-8">
            <div className="max-w-sm p-3">
              <img
                src={v4}
                alt="Diversity"
                className="rounded-lg mb-4"
              />
              <h1 className="lg:text-lg text-xl font-bold mb-2">Diversity</h1>
              <p className="font-normal md:text-sm leading-relaxed text-gray-600">
                Exploring international recipes means celebrating the cultures and communities behind them. We honor diversity in every dish, ensuring respect, authenticity, and inclusivity in the meals we curate.
              </p>
            </div>
            <div className="max-w-sm p-3">
              <img
                src={v5}
                alt="Customer-Focus"
                className="rounded-lg mb-4"
              />
              <h1 className="lg:text-lg text-xl font-bold mb-2">Customer-Focus</h1>
              <p className="font-normal md:text-sm leading-relaxed text-gray-600">
                You inspire everything we do! Your feedback helps us improve our recipes, enhance our services, and provide a seamless cooking experience. Tell us what you love and what we can do better—we’re here to make <b>AnyFeast Recipe Kits</b> the perfect fit for your kitchen.

                <b> #CookWithPurpose #SmartCooking #SustainableMeals </b>
              </p>
            </div>
          </div>
        </div>

        <div className="lg:py-8 py-2 px-6 md:px-8 lg:px-32 bg-graybg">
          {/* Heading Section */}
          <div className="mb-8">
            <h2 className="lg:text-3xl text-2xl font-bold mb-2 font-sans">
              From our <span className="text-red-500">Blog</span>
            </h2>
            <p className="text-[#000] lg:text-xl text-[13px] font-sans">
              Discover the latest in smart cooking, global flavors, and sustainable eating. Get inspired by the passion and innovation behind AnyFeast!

              <b>#AnyFeastBlog #SmartCooking #SustainableLiving</b>
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
            {getBlogs.map((blog: any) => (
              <div className="overflow-hidden" key={blog.id}>
                <img
                  src={blog.featured_image_url || placeholder}
                  alt={blog.title}
                //className="w-full h-48 object-cover"
                />
                <div className="p-4">
                  <p className="text-sm text-gray-500 mb-2">
                    {convertDateWithMoment(blog.post_date)} | 2 min read
                  </p>
                  <h3 className="text-lg font-bold mb-2 line-clamp-1">{blog.title}</h3>
                  <div className="flex justify-between items-start">
                    <p className="text-gray-600 mb-4 flex-1 line-clamp-2">
                      {truncateText(blog?.content)}
                    </p>
                    <Link to={`/${storedCountry}/blog/${blog.slug}`}>
                      <img
                        src={send}
                        alt="Blog 2"
                        className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-10 h-10 ml-1"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
            {/* <div className="overflow-hidden">
              <img
                src={bb2}
                alt="Blog 2"
              // className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <p className="text-sm text-gray-500 mb-2">
                  Published on July 14, 2024 | 2 min read
                </p>
                <h3 className="text-lg font-bold mb-2 line-clamp-1">Celebrating a Milestone: Anyfeast's Demo Day</h3>
                <p className="text-gray-600 mb-4 flex items-center justify-between line-clamp-2">
                  "If you don't dream, you won't achieve anything."- Richard Branson. For me, these...

                  <img
                    src={send}
                    alt="Blog 2"
                    className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-10 h-10 ml-1"
                  />
                </p>
              </div>
            </div>

            <div className="overflow-hidden">
              <img
                src={bb3}
                alt="Blog 3"
              />
              <div className="p-4">
                <p className="text-sm text-gray-500 mb-2">
                  Published on August 30, 2024 | 2 min read
                </p>
                <h3 className="text-lg font-bold mb-2 line-clamp-1">From Vision to Victory: Celebrating Anyfeast's</h3>
                <p className="text-gray-600 mb-4 flex items-center justify-between line-clamp-2">
                  "Create a clear vision for your business, and then stick to it." – Adeo Ressi
                  <img
                    src={send}
                    alt="Blog 2"
                    className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-10 h-10 ml-1"
                  />
                </p>
              </div>
            </div> */}
          </div>
        </div>

      </div>
    </div>
  );
};

export default OurStoryTwo;
