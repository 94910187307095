import React from "react";
import Truck from "../../../src/assets/images/Truck.png";
import Lock from "../../../src/assets/images/Lock.png";
import Package from "../../../src/assets/images/cod.png";
import facebook from "../../../src/assets/images/facebook.png";
import youtube from "../../../src/assets/images/youtube.png";
import linkedin from "../../../src/assets/images/linkedin.png";
import logo from "../../../src/assets/images/footerLogo.png";
import arrow from "../../../src/assets/images/RightArrow.png";
import instagram from "../../../src/assets/images/instagram.png";

import { Link } from "react-router-dom";

interface NewsletterProps {
  isMobile: boolean;
}

const Newsletter: React.FC<NewsletterProps> = ({ isMobile }) => {
  return (
    <div>
      {isMobile && (
        <h5 className="font-bold mb-3 font-sans text-lg">
          Join Our Newsletter
        </h5>
      )}
      <h5 className="font-bold mb-3 font-sans text-sm">
        Get tasty recipes directly in your inbox
      </h5>
      <div className="flex sm:p-0 rounded-md overflow-hidden">
        <input
          type="email"
          placeholder="Email"
          className="w-full px-3 py-2 placeholder-greytext placeholder:text-[14px] text-black focus:outline-none rounded-l-md"
        />
        <span className="bg-greylight h-full w-[1px]"/>
        <button
          type="submit"
          className="px-3 py-2 bg-white text-black rounded-r-md"
        >
          <img src={arrow} alt="Arrow" className="w-5" />
        </button>
      </div>
    </div>
  );
};

const SocialLinks: React.FC = () => {
  return (
    <div className="space-y-4">
      <h5 className="font-bold text-sm">Social Links</h5>
      <div className="flex space-x-6">
        <a
          href="https://www.facebook.com/AnyFeast.Official/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebook} alt="Facebook" className="w-8 h-8" />
        </a>
        <a
          href="https://www.linkedin.com/company/anyfeast"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin} alt="LinkedIn" className="w-8 h-8" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCP0NuGSINnnAxYJpesrC_qA"
          target="_blank"
          rel="noreferrer"
        >
          <img src={youtube} alt="Youtube" className="w-8 h-8" />
        </a>
        <a
          href="https://www.instagram.com/anyfeast_official/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="instagram" className="w-8 h-8" />
        </a>
      </div>
    </div>
  );
};

const Footer: React.FC = () => {
  const storedCode = localStorage.getItem("countryCode");
  const storedCountry = localStorage.getItem("selectedCountry");
  return (
    <footer className="bg-black text-white py-10 md:px-10 lg:px-44">
      <div className="container mx-auto px-5">
        {/* Services Section */}
        <div className="flex flex-col space-y-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 mb-8">
          {/* Mobile: Stack vertically, Desktop: Spread horizontally */}
          <div className="flex items-center sm:justify-start">
            <div className="flex items-center space-x-3">
              <img src={Package} alt="Package" className="w-6 h-6" />
              <span className="font-semibold">Cash On Delivery</span>
            </div>
          </div>

          <div className="flex items-center sm:justify-center">
            <div className="flex items-center space-x-3">
              <img src={Lock} alt="Lock" className="w-6 h-6" />
              <span className="font-semibold">100% Secure Payments</span>
            </div>
          </div>

          <div className="flex items-center sm:justify-end">
            <div className="flex items-center space-x-3">
              <img src={Truck} alt="Truck" className="w-6 h-6" />
              <span className="font-semibold">
                {storedCode === "UK"
                  ? "Free Shipping over £20"
                  : "Free Shipping over INR299"}
              </span>
            </div>
          </div>
        </div>

        {/* Divider line after services */}
        <div className="border-t border-gray-600 mb-8"></div>

        {/* Mobile Newsletter */}
        <div className="sm:hidden mb-8">
          <Newsletter isMobile={true} />
        </div>

        {/* Logo for Mobile */}
        <div className="sm:hidden mb-8">
          <img src={logo} alt="AnyFeast Logo" className="h-10" />
        </div>

        {/* Main Footer Content */}
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-8">
          {/* AnyFeast Column */}
          <div>
            <h5 className="font-bold mb-4">AnyFeast</h5>
            <ul className="space-y-3">
              <li>
                <Link to={`/${storedCountry}/shop`} className="text-sm hover:text-gray-300">
                  Shop
                </Link>
              </li>
              <li>
                <Link to={`/${storedCountry}/persona/united-family`} className="text-sm hover:text-gray-300">
                  Explore Recipe
                </Link>
              </li>
              <li>
                <Link to={`/${storedCountry}/OurStory`} className="text-sm hover:text-gray-300">
                  Our Story
                </Link>
              </li>
              <li>
                <Link to={`/${storedCountry}/blog`} className="text-sm hover:text-gray-300">
                  Blog
                </Link>
              </li>
            </ul>
          </div>

          {/* Support Column */}
          <div>
            <h5 className="font-bold mb-4">Support</h5>
            <ul className="space-y-3">
              <li>
                <Link to={`/${storedCountry}/OurStory`} className="text-sm hover:text-gray-300">
                  Our Story
                </Link>
              </li>
              <li>
                <Link to={`/${storedCountry}/contact`} className="text-sm hover:text-gray-300">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          {/* Company Column */}
          <div className="col-span-2 sm:col-span-1">
            <h5 className="font-bold mb-4">Company</h5>
            <ul className="space-y-3">
              <li>
                <Link
                  to={`/${storedCountry}/TermsConditions`}
                  className="text-sm hover:text-gray-300"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  to={`/${storedCountry}/CancellationRefunds`}
                  className="text-sm hover:text-gray-300"
                >
                  Cancellation & Refund Policy
                </Link>
              </li>
              <li>
                <Link
                  to={`/${storedCountry}/ShippingPolicy`}
                  className="text-sm hover:text-gray-300"
                >
                  Shipping Policy
                </Link>
              </li>
              <li>
                <Link
                  to={`/${storedCountry}/PrivacyPolicy`}
                  className="text-sm hover:text-gray-300"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to={`/${storedCountry}/AI`}
                  className="text-sm hover:text-gray-300"
                >
                  AI Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* Newsletter and Social Links Column - Desktop Only */}
          <div className="hidden sm:block space-y-8">
            <Newsletter isMobile={false} />
            <SocialLinks />
          </div>
        </div>

        {/* Social Links for Mobile */}
        <div className="sm:hidden border-t border-gray-600 pt-8 mt-8">
          <SocialLinks />
        </div>

        {/* Footer Bottom - Desktop Only */}
        <div className="hidden sm:block mt-8 pt-8 border-t border-gray-600">
          <div className="flex items-center space-x-4">
            <img src={logo} className="h-10" alt="AnyFeast Logo" />
            <p className="text-gray-400 text-sm">
              World flavours delivered, kitchen convenience guaranteed. Team up
              with our vendors and partners to bring global recipes to life,
              right at home.
            </p>
          </div>
          <p className="mt-4 text-sm">

            By continuing past this page, you agree to our Terms of Service, Cookie Policy, Privacy Policy, and Content Policies. All trademarks are properties of their respective owners. © 2025 AnyFeast™. All rights reserved.

          </p>
        </div>

        {/* Footer Bottom - Mobile Only */}
        <div className="sm:hidden text-xs text-gray-400 mt-8 pt-4 border-t border-gray-600">
          {/* <div className="flex space-x-4 mb-2">
            <span>English</span>
            <span>Privacy</span>
            <span>Legal</span>
          </div> */}

          <p>By continuing past this page, you agree to our Terms of Service, Cookie Policy, Privacy Policy, and Content Policies. All trademarks are properties of their respective owners. © 2025 AnyFeast™. All rights reserved.</p>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
